import React from "react";
import Svg from "../svg";

function Footer() {
  return (
    <div className="footer">
      <div className="footer-nav">
        <div className="f-nav-block contact-list">
          {/* <h4>Come, say hi!</h4> */}
          <div>
            <ul>
              <li>
                <Svg
                  height="49"
                  width="301"
                  viewBox="0 0 301 49"
                  svgIcon="company-logo"
                />
              </li>
              <li className="location-pin">
                <div>
                  <Svg
                    width="19"
                    height="22"
                    viewBox="0 0 19 22"
                    svgIcon="location-footer"
                  />
                </div>
                <div className="m-l-10">
                  <span>
                    Hyderabad
                    <p>
                      2nd Floor, Plot #97, Gafoor Nagar, Madhapur, Hyderabad,
                      Telangana 500081
                    </p>
                  </span>
                  <span>
                    Gurgaon
                    <p>
                      15, L-13, DLF Phase 2, Sector 25, Gurugram, Haryana 122001
                    </p>
                  </span>
                </div>
              </li>
              <li className="contact-phone">
                {" "}
                <Svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  svgIcon="phone-footer"
                />
                <span className="m-l-10">08047249992</span>
              </li>
              <li>
                {" "}
                <Svg
                  width="20"
                  height="19"
                  viewBox="0 0 20 19"
                  svgIcon="email-footer"
                />
                <a rel="noreferrer" href="mailto:grad@gradright.com">
                  <span className="m-l-10">grad@gradright.com</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
        {/* 2 */}
        <div className="f-nav-block help-links">
          <h4>Who we are</h4>
          <div>
            <ul>
              <li>
                <a rel="noreferrer" href="https://gradright.com/team.html">
                  Team
                </a>
              </li>
              <li>
                <a
                  rel="noreferrer"
                  href="https://gradright.com/team-support.html"
                >
                  Mentors
                </a>
              </li>
              <li>
                <a rel="noreferrer" href="https://gradright.com/team.html">
                  Board of Advisors
                </a>
              </li>
              <li>
                <a
                  rel="noreferrer"
                  href="https://gradright.com/team-support.html"
                >
                  Acknowledgements
                </a>
              </li>
              <li>
                <a rel="noreferrer" href="https://gradright.com/methodology.html">
                  {" "}
                  Our Methodology
                </a>
              </li>
              <li>
                <a
                  rel="noreferrer"
                  href="https://gradright.com/testimonials.html"
                >
                  Testimonials
                </a>
              </li>
            </ul>
          </div>
        </div>
        {/* 3 */}
        <div className="f-nav-block help-links">
          <h4>Let Us Help You</h4>
          <div>
            <ul>
              <li>
                <a
                  rel="noreferrer"
                  href="https://gradright.com/program-list.html"
                >
                  Top 500 programs
                </a>
              </li>
              <li>
                <a rel="noreferrer" href="https://gradright.com/privacy.html">
                  Privacy Policy
                </a>
              </li>
              <li>
                <a rel="noreferrer" href="https://gradright.com/terms.html">
                  Terms &amp; Conditions
                </a>
              </li>
              <li>
                <a
                  rel="noreferrer"
                  href="http://blog.gradright.com/"
                  target="_blank"
                >
                  Blog
                </a>
              </li>
              <li>
                <a
                  rel="noreferrer"
                  href="https://gradright.zohorecruit.in/jobs/Careers"
                  target="_blank"
                >
                  Careers
                </a>
              </li>
            </ul>
          </div>
        </div>
        {/* 4 */}
        <div className="f-nav-block social-links">
          <h4>Join the community</h4>
          <div>
            <ul>
              <li>
                <a
                  rel="noreferrer"
                  href="https://www.facebook.com/gradright/"
                  target="_blank"
                  className="fb"
                >
                  Facebook
                </a>
              </li>
              <li>
                <a
                  rel="noreferrer"
                  href="https://www.linkedin.com/company/gradright/about/"
                  target="_blank"
                  className="in"
                >
                  LinkedIn
                </a>
              </li>
              <li>
                <a
                  rel="noreferrer"
                  href="https://www.instagram.com/grad.right/"
                  target="_blank"
                  className="insta"
                >
                  Instagram
                </a>
              </li>
              <li>
                <a
                  rel="noreferrer"
                  href="https://api.whatsapp.com/send?phone=919056877079&amp;text=Hello there!"
                  target="_blank"
                  className="wapp"
                >
                  Whatsapp
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="copyright">
        Copyright © 2022 Gradright | All Rights Reserved
      </div>
    </div>
  );
}

export default Footer;
